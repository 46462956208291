import { IShopNavigationItemContent } from '@x/content/client';
import { MenuNode } from '@x/ecommerce-shop/app/core/menu/services/menu.service';
import { IShopChannelDetail, IShopChannelRootTaxon } from '@x/ecommerce/shop-client';

export class MenuRemoveActive {
  static readonly type = '[Menu] Remove active';
}

export class MenuFetch {
  static readonly type = '[Menu] Fetch';
  constructor(
    public activeChannel: IShopChannelDetail,
    public navItems: IShopNavigationItemContent[],
    public regionId?: number | null,
  ) {}
}

export class MenuSet {
  static readonly type = '[Menu] Set';
  constructor(
    public channelCode: string,
    public menuTree: MenuNode,
    public rootTaxon: IShopChannelRootTaxon,
    public regionId?: number | null,
  ) {}
}

export class MenuSetActive {
  static readonly type = '[Menu] Set active';

  constructor(public node: MenuNode) {}
}

export class MenuSetActiveFromTaxonId {
  static readonly type = '[Menu] Set active from taxonId';

  constructor(public taxonId: number) {}
}
