import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { OperationObserverService } from '@x/common/operation';
import { MediaType } from '@x/common/utils';
import { IShopNavigationItemContent } from '@x/content/client';
import { ChannelState } from '@x/ecommerce-shop/app/channel/state';
import { MenuFetch } from '@x/ecommerce-shop/app/core/menu/state/menu.actions';
import { MenuState } from '@x/ecommerce-shop/app/core/menu/state/menu.state';
import { RegionContextService } from '@x/ecommerce-shop/app/core/region/services/region-context.service';
import { UrlService } from '@x/ecommerce-shop/app/core/url/url.service';
import { IShopConfig, SHOP_CONFIG } from '@x/ecommerce-shop/config/shop.config';
import { IShopTaxonDetail, IShopTaxonItem } from '@x/ecommerce/shop-client';
import { combineLatest, switchMap } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export interface MenuNode {
  id: number;
  name: string;
  url: string;
  position: number;
  isEdge: boolean;
  parentId?: number | null | undefined;
  children?: MenuNode[];
  parent?: MenuNode;
  depth: number;
  slug: string;
  contentRef?: string | null;
  media?: {
    url: string;
    type: MediaType;
  };
  source: 'taxon' | 'content';
}

@Injectable()
export class MenuService {
  private _rootTaxon?: IShopTaxonDetail;

  constructor(
    private urlService: UrlService,
    private store: Store,
    private operation: OperationObserverService,
    private regionContext: RegionContextService,
    @Inject(SHOP_CONFIG) private shopConfig: IShopConfig,
  ) {}

  getMenuNodeTreeFromTaxonDetail(
    rootTaxon: IShopTaxonDetail,
    channelNavItems: IShopNavigationItemContent[] = [],
  ): MenuNode {
    this._rootTaxon = { ...rootTaxon };
    const { descendents, id, name, slug, position, contentRef } = this._rootTaxon;

    const rootNode: MenuNode = {
      id,
      name,
      url: this.urlService.taxonUrl(id, slug),
      slug,
      position,
      isEdge: false,
      depth: 0,
      contentRef,
      source: 'taxon',
    };

    // Filter out taxons that are hidden or have no products
    const descendantsWithProducts: IShopTaxonItem[] = descendents.filter(
      ({ hidden, productCount, descendentProductCount }) =>
        !hidden && (productCount !== 0 || descendentProductCount !== 0),
    );

    rootNode.children = this._buildTreeFromArray(descendantsWithProducts, id, rootNode);

    // Integrate channel navigation items
    if (channelNavItems.length > 0) {
      channelNavItems.forEach((item) => {
        if (!item.taxonId) return;

        const parentNode = this.findMenuNodeByTaxonId(rootNode, item.taxonId);
        if (parentNode) {
          const slug = item.text.replace(/\s+/g, '-').toLowerCase();
          const newNode: MenuNode = {
            id: Date.now(),
            name: item.text,
            url: `/${rootTaxon.channelCode}/${item.url}/${slug}`,
            position: item.position ?? 0,
            isEdge: true,
            depth: parentNode.depth + 1,
            slug,
            media: item.media ? { url: item.media.url, type: item.media.type } : undefined,
            source: 'content',
          };

          parentNode.children = [...(parentNode.children || []), newNode].sort(
            (a, b) => a.position - b.position,
          );
        }
      });
    }

    return rootNode;
  }

  getActiveAscendants(item: MenuNode, arr: MenuNode[] = []): MenuNode[] {
    if (item.parent) {
      arr = [item.parent, ...arr];
      return this.getActiveAscendants(item.parent, arr);
    }

    return arr;
  }

  findMenuNodeByTaxonId(menuNode: MenuNode | null, id: number): MenuNode | undefined {
    if (!menuNode) return;

    if (menuNode.id === id) return menuNode;

    if (menuNode.children) {
      for (const node of menuNode.children) {
        let foundNode = this.findMenuNodeByTaxonId(node, id);
        if (foundNode) return foundNode;
      }
    }

    return;
  }

  fetchMenuOperation$ = combineLatest([
    this.store.select(ChannelState.activeChannel).pipe(filter(Boolean)),
    this.store
      .select(ChannelState.activeChannelContent)
      .pipe(map((content) => content?.navItems ?? [])),
    this.regionContext.regionIdChange$,
  ]).pipe(
    switchMap(([activeChannel, navItems, regionId]) => {
      return this.operation.observe(
        this.store
          .dispatch(new MenuFetch(activeChannel, navItems, regionId))
          .pipe(switchMap(() => this.store.selectOnce(MenuState.mainMenu))),
      );
    }),
    // shareReplay(),
  );

  private getPath(taxon: IShopTaxonItem, nodeDepth: number): string {
    if (
      this.shopConfig.features.mainMenu.taxonTargetLevel.includes(nodeDepth) &&
      taxon.descendentProductCount !== 0
    ) {
      return this.urlService.taxonUrl(taxon.id, taxon.slug);
    }

    return this.urlService.productListingUrl(taxon.id, taxon.slug);
  }

  private _buildTreeFromArray(
    arr: IShopTaxonItem[],
    parentId: number,
    parent: MenuNode,
  ): MenuNode[] {
    let descendents = arr.filter((taxon) => taxon.parentId === parentId);

    return descendents
      .map((taxon) => {
        const { id, name, slug, position, parentId, contentRef } = taxon;

        const depth = parent.depth + 1;

        let node: MenuNode = {
          id,
          name,
          url: this.getPath(taxon, depth),
          position,
          parentId,
          slug,
          isEdge: true,
          depth,
          parent,
          contentRef,
          source: 'taxon',
        };

        const children = this._buildTreeFromArray(arr, taxon.id, node).map((child) => {
          child.parent = node;
          return child;
        });

        if (children.length > 0) {
          node.children = children;
          node.isEdge = false;
        }

        return node;
      })
      .sort((a, b) => a.position - b.position);
  }
}
