import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { MenuNode, MenuService } from '@x/ecommerce-shop/app/core/menu/services/menu.service';
import { ProductDetailState } from '@x/ecommerce-shop/app/product-view/resolvers/product.resolver';
import { IShopChannelRootTaxon, ShopTaxonService } from '@x/ecommerce/shop-client';
import { shareReplay, tap } from 'rxjs/operators';
import {
  MenuFetch,
  MenuRemoveActive,
  MenuSet,
  MenuSetActive,
  MenuSetActiveFromTaxonId,
} from './menu.actions';

export interface MenuStateModel {
  activeNode: MenuNode | null;
  activeNodeAscendants: MenuNode[];
  nodeTree: MenuNode | null;
  rootTaxon: IShopChannelRootTaxon | null;
  channelCode: string | null;
  regionId: number | null;
}

const defaults: MenuStateModel = {
  activeNode: null,
  nodeTree: null,
  rootTaxon: null,
  activeNodeAscendants: [],
  channelCode: null,
  regionId: null,
};

@State<MenuStateModel>({
  name: 'menuState',
  defaults,
})
@Injectable()
export class MenuState {
  constructor(
    private menuService: MenuService,
    @Inject(LOCALE_ID)
    private readonly locale: string,
    private shopTaxonService: ShopTaxonService,
    private route: ActivatedRoute,
  ) {}

  @Selector()
  static nodeTree(state: MenuStateModel): MenuNode | null {
    return state.nodeTree;
  }

  @Selector()
  static mainMenu(state: MenuStateModel): MenuNode[] {
    return state.nodeTree?.children ?? [];
  }

  @Selector()
  static activeNode(state: MenuStateModel) {
    return state.activeNode;
  }

  @Selector()
  static activeNodeAscendants(state: MenuStateModel) {
    return state.activeNodeAscendants;
  }

  @Selector()
  static activeNodeAscendantsIds(state: MenuStateModel): number[] {
    return state.activeNodeAscendants.map((node) => node.id);
  }

  @Selector()
  static activeNodeAndAscendantIds(state: MenuStateModel): number[] {
    let arr: number[] = [...state.activeNodeAscendants.map((node) => node.id)];

    if (state.activeNode) {
      arr = [state.activeNode.id, ...arr];
    }
    return arr;
  }

  @Selector()
  static topLevelActiveNode(state: MenuStateModel) {
    return state.activeNodeAscendants[1];
  }

  @Action(MenuFetch)
  menuFetch(
    { dispatch, getState }: StateContext<MenuStateModel>,
    { activeChannel: { code }, navItems, regionId }: MenuFetch,
  ) {
    const { channelCode, regionId: regionIdFromState, rootTaxon, nodeTree } = getState();

    if (channelCode === code && regionIdFromState === regionId && rootTaxon && nodeTree) {
      const menuTree = this.menuService.getMenuNodeTreeFromTaxonDetail(rootTaxon, navItems);
      dispatch(new MenuSet(code, menuTree, rootTaxon, regionId));
      return;
    }

    const rootTaxon$ = this.shopTaxonService
      .fetchChannelRootTaxonDetail(code, this.locale, regionId)
      .pipe(shareReplay());

    return rootTaxon$.pipe(
      tap((rootTaxon) => {
        const menuTree = this.menuService.getMenuNodeTreeFromTaxonDetail(rootTaxon, navItems);

        dispatch(new MenuSet(code, menuTree, rootTaxon, regionId));
      }),
    );
  }

  @Action(MenuSet)
  menuSet(
    { patchState, dispatch }: StateContext<MenuStateModel>,
    { menuTree, regionId, rootTaxon, channelCode }: MenuSet,
  ) {
    patchState({
      nodeTree: { ...menuTree },
      activeNodeAscendants: [],
      rootTaxon,
      channelCode,
      regionId,
    });

    const taxonId = this.getActiveTaxonId();
    if (taxonId) dispatch(new MenuSetActiveFromTaxonId(Number(taxonId)));
  }

  @Action(MenuRemoveActive)
  resetMenu({ patchState }: StateContext<MenuStateModel>) {
    patchState({
      activeNode: null,
      activeNodeAscendants: [],
    });
  }

  @Action(MenuSetActive)
  setActive({ patchState, getState }: StateContext<MenuStateModel>, { node }: MenuSetActive) {
    const { nodeTree } = getState();

    if (!nodeTree) return;

    const activeAscendants: MenuNode[] = this.menuService.getActiveAscendants(node, []);

    patchState({
      activeNode: { ...node },
      activeNodeAscendants: [...activeAscendants],
    });
  }

  @Action(MenuSetActiveFromTaxonId)
  setActiveFromTaxonId(
    { patchState, getState, dispatch }: StateContext<MenuStateModel>,
    { taxonId }: MenuSetActiveFromTaxonId,
  ) {
    const { nodeTree } = getState();

    if (!nodeTree) return;

    const menuItem = this.menuService.findMenuNodeByTaxonId(nodeTree, taxonId);

    if (!menuItem) {
      patchState({
        activeNode: undefined,
      });

      return;
    }

    return dispatch(new MenuSetActive(menuItem));
  }

  private getActiveTaxonId(): Number | undefined {
    try {
      const activeSnapshot = this.getActiveRouteSnapshot();

      const taxonIdFromParams = Number(activeSnapshot.params?.taxonId);

      const productDetailState = activeSnapshot.data?.productDetailState as
        | ProductDetailState
        | undefined;

      return taxonIdFromParams || productDetailState?.productDetail?.mainTaxon?.id;
    } catch (e) {
      return undefined;
    }
  }

  private getActiveRouteSnapshot(
    snapshot: ActivatedRouteSnapshot = this.route.snapshot,
  ): ActivatedRouteSnapshot {
    let activeSnapshot = snapshot;
    while (activeSnapshot.firstChild) {
      activeSnapshot = activeSnapshot.firstChild;
    }
    return activeSnapshot;
  }
}
